.popup_container {
  display: flex;
  /* max-height: 90px; */
  height: auto;
  width: auto;
}
.popup_name_container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.popup_name {
  margin-top: 5px;
  margin-bottom: -10px;
  font-size: 13px;
}
.popup_name_open {
  color: green;
  font-size: 13px;
}
.popup_name_close {
  color: red;
  font-size: 13px;
}
.popup_icon {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: orange;
  color: #1b1b30;
  border-radius: 50px;
  height: 33px;
  width: 33px;
}
.custom-popup .mapboxgl-popup-content {
  background-color: #1b1b30;
  border-radius: 30px;
  padding: 0;
}
.custom-popup .mapboxgl-popup-tip {
  border-top-color: #1b1b30;
  height: auto;
  width: auto;
}
.popup_image {
  margin-left: 5px;
  height: 90%;
  width: 70px;
  border-radius: 30px 25px 25px 30px;
  object-fit: cover;
  max-height: 100px;
  /* margin-top: 8%;
  margin-bottom: 5%; */
}

.popup_icon_icon {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  margin: auto;
}
.popup_icon {
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -6px;
}
.delivery_icon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-top: -7px;
}
.dot {
  display: inline-block;
  color: white;
  font-size: 2.5rem;
}
.min_order {
  margin-top: -20px;
  color: white;
  min-width: 90px;
}
.popupLikes {
  color: white;
}
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.huerotate {
  filter: hue-rotate(170deg);
  -webkit-filter: hue-rotate(170deg);
}

.invert {
  filter: invert(80%);
  -webkit-filter: invert(80%);
}

.img-dark {
  /*filter: sepia(100%) hue-rotate(190deg) brightness(0.5) saturate(500%);*/
  filter: saturate(500%) contrast(60%) invert(80%);
}

.popup_container {
  margin-bottom: 5px;
}
.popup_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.favorite_adresses {
  display: flex;
  flex-direction: column;
}
@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.grow-animation {
  animation: grow 0.5s ease-in-out forwards;
}
.sheet_on_map {
  z-index: -1;

  margin-left: 50px;
  margin-right: 55px;
}

.shaker-home-containerStore {
  z-index: 999999;
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  width: 80%;
  position: fixed;
  top: 200px;
  left: 10%;
  right: 10%;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.shaker-home-container.shaker-open {
  transform: translateY(0%);
}
.shaker-home-container.shaker-close {
  transform: translateY(100%);
}
.shaker-home-container-cancel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shaker-home-container-cancel-heading {
  font-size: 18px;
  margin-left: 16px;
  font-weight: 600;
}
.shaker-home-body {
  height: 100vh;
  padding: 16px;
}

#target {
  width: 30px;
  height: 30px;
  background-color: transparent;
  position: absolute;
  z-index: 1000;
}
/* .sheet-wrapper {
  position: fixed;
  bottom: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999999999999999;
  width: 100%;
  margin-top: 150px;
} */

.left-button,
.right-button {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  border: none;
  z-index: 999999999;
  cursor: pointer;
  height: 90px;
  width: 40px;
  margin-top: 60px;
  max-width: 40px;
  /* margin-left: -10px; */
}

@media only screen and (min-width: 555px) {
  .left-button,
  .right-button {
    height: 90px;
    width: 40px;
    margin-top: 60px;
    /* margin-left: -10px; */
  }
}
/* [data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  left: 50px !important;
  right: 60px !important;
} */

.botttom-sheet-map-container[data-rsbs-has-header='false'] [data-rsbs-header] {
  z-index: 9999999;
  background-color: white !important;
  margin-left: 40px;
  margin-right: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: none !important;
}

.botttom-sheet-map-container[data-rsbs-is-blocking='false']
  [data-rsbs-overlay] {
  z-index: 9999999 !important;
  box-shadow: none;
}
.bottom-sheet-map {
  height: 100vh;
  background-color: white;
  margin-left: -40px;
  margin-right: -40px;
  z-index: 99999999;

  overflow: scroll;
}
.bottom-sheet-map::-webkit-scrollbar {
  display: none;
}
.bottom-sheet-map-full-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 100%; /* Ensures the container takes the full width of the parent */
  align-items: stretch; /* Makes sure the child elements stretch to fill the height if needed */
}

.bottom-sheet-map-full-container > * {
  flex-grow: 1; /* Ensures that the child elements will stretch to fill the available space */
}
/* @media only screen and (max-width: 555px) {
  .bottom-sheet-map-full-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: -30px;
    
  }
} */
[data-rsbs-footer],
[data-rsbs-header] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
[data-rsbs-header] {
  box-shadow: none !important;
}
