.form-card {
  background-color: white;
  /* height: 400px; */
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.form-card-title {
  font-size: 25px;
}
.otpSeparator {
  content: ' ' !important;
}

.loading-button {
  font-size: 24px;
  border: none;
  background: #46bb78;
  border-radius: 3px;
  color: white;
  height: 60px;
  width: 120px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
}
.loading-button:hover span {
  transform: translateY(-3px);
}
.loading-button:focus {
  outline: none;
}

.loading-button span {
  position: absolute;
  right: 32px;
  top: 16px;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.loading-button.loading span {
  top: -50px;
  right: 0;
  display: none;
}

.loading-button.success span {
  top: -50px;
}
.loading-button.falure span {
  top: -50px;
}

.loading-button.success {
  pointer-events: none;
  background: #46bb78;
}
.loading-button.falure {
  pointer-events: none;
  background: red;
}

.loading-button.warning {
  pointer-events: none;
  background: orange;
}

.loading-button.loading {
  width: 60px;
  border-radius: 50%;
  animation: bounce 1s infinite;
  pointer-events: none;
}

.loading-button.success:after {
  position: absolute;
  content: '✔';
  color: transparent;
  text-shadow: 0 0 0 white;
  display: block;
  top: 60px;
  left: 50px;
  transition: 0.3s;
  transition-delay: 0.1s;
}
.loading-button.falure:after {
  position: absolute;
  content: ' x';
  color: transparent;
  text-shadow: 0 0 0 white;
  display: block;
  top: 60px;
  left: 50px;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.loading-button.warning:after {
  position: absolute;
  content: ' x';
  color: transparent;
  text-shadow: 0 0 0 white;
  display: block;
  top: 60px;
  left: 50px;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.loading-button.success:after {
  top: 12px;
  left: 50px;
}

.loading-button.falure:after {
  top: 12px;
  left: 50px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    height: 60px;
  }
  40% {
    transform: translateY(-30px);
    height: 68px;
  }
  60% {
    transform: translateY(-15px);
    height: 68px;
  }
}

.loading-button.disable {
  background-color: grey;
}
.call-again {
  text-align: center;
}
.otp-input-container {
  margin-bottom: 8px;
}
