.wallet-balance-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: aliceblue;
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
}
.wallet-balance-text {
  font-size: large;
}
.wallet-balance-amount {
  font-weight: bold;
}
.wallet-loyality-program-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  background-color: aliceblue;
  padding: 20px;
}
.wallet-loyality-program-link {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
}
