.filter {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  height: 30px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 10px;
  cursor: pointer;
  padding-right: 30px;
}

.filter--dark {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.filter__icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.filter--dark .filter__icon {
  filter: invert(1);
}

.filter__title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  user-select: none;
  white-space: nowrap;
}

.filter--transparent {
  opacity: 0.5;
}
