.navbar__container {
  min-height: 62px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  transform: rotate(-1deg) !important;
}
.navbar__container > .navbar {
  transform: rotate(1deg) !important;
}
@media only screen and (min-width: 768px) {
  .navbar__container,
  .navbar__container > .navbar {
    transform: unset !important;
  }
}
.navbar {
  display: flex;
}
.navbar__center .ant-input::placeholder {
  /*This is the search input placeholder*/
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(27, 27, 49, 0.4);
  width: auto;
}

@media only screen and (max-width: 500px) {
  /* Iphone 14 pro max */
  .navbar {
    display: grid !important;
    grid-template-columns: 50px auto 50px !important;
    column-gap: 17px !important;
  }
}
@media only screen and (max-width: 375px) {
  .navbar {
    grid-template-columns: 40px auto 40px !important;
  }
}
@media only screen and (max-width: 345px) {
  .navbar {
    column-gap: 13px !important;
  }
}
.navbar__center {
  display: flex !important;
  justify-content: space-between !important;
  flex-grow: 2 !important;
  margin: 0 18px !important;
}
.navbar__center span {
  display: flex;
}

@media only screen and (max-width: 500px) {
  /* Iphone pro max (430px) */
  .navbar__center {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin: 0 !important;
    flex-grow: inherit !important;
    width: 100% !important;
  }
  .navbar__center > span {
    width: 100%;
    height: 50px;
  }
}

@media only screen and (max-width: 375px) {
  /* Iphone SE */
  .navbar__center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    flex-grow: inherit;
    width: 100%;
  }
  .navbar__center > span {
    height: 35px;
  }
  .navbar__center .anticon-search {
    height: 14px;
  }
  .navbar__center input {
    font-size: 14px;
  }
}

.navbar__center--title {
  justify-content: space-around;
}

.navbar__center .bigswitcher {
  min-width: 220px;
}

.navbar .navbar__center__search {
  height: 100% !important;
  background-color: var(--light-grey) !important;
  border-radius: 100px !important;
  border-color: var(--light-grey) !important;
  max-width: 355px !important;
}

.navbar__center__searchicon {
  width: 19px !important;
  height: 19px !important;
  background-size: contain !important;
  margin-left: 9px !important; /*11px automatically added by ant design*/
  margin-right: 10px !important; /*4px automatically added by ant design*/
}

.navbar--white .navbar__center__search {
  background-color: var(--white-color) !important;
  border-color: var(--white-color) !important;
}

.navbar__center__search .anticon.anticon-search {
  color: rgba(var(--primary-color-rgb), 0.4) !important;
}

.navbar__center__route {
  margin-left: 10px !important;
}

.navbar__center__title {
  font-size: 20px !important;
  text-align: center !important;
  margin: 7.5px 0 !important;
  font-weight: 600 !important;
  color: var(--primary-color) !important;
}

.navbar__left,
.navbar__right {
  min-width: 46px !important;
}

.navbar__left img,
.navbar__right img {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 500px) {
  .navbar__left,
  .navbar__right {
    min-width: 50px;
    min-height: 50px;
  }
  .price {
    margin-top: 14px;
  }
  /* .navbar__center--title {
    margin-left: 50px;
    margin-top: -42px;
  }
  .title_under_430px {
    margin-left: -50px;
  }
  .navbar {
    height: 60px;
  } */
}
@media screen and (max-width: 375px) {
  .navbar__left,
  .navbar__right {
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .profile_picture_avator {
    margin-top: 14px;
  }
  .ChiamaCocaiSupport {
    /* padding-inline: 35px; */
    /* padding-right: 20px; */
    margin-top: 20px !important;
    width: 80px;
    padding-left: 5px;
    margin-left: -10px;
  }
  .navbar__left img:first-child,
  .navbar__right img:first-child {
    width: 40px !important;
    /* height: 40px !important; */
  }
  .navbar__left img:last-child {
    width: 80px;
    height: 80px;
  }
  .rounded-div {
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    width: 40px !important;
    height: 40px !important;
    justify-content: center;
    align-items: center;
  }
  .rounded-div_cancel {
    display: flex;

    border-radius: 50%;

    background-color: white;
    width: 40px !important;
    height: 40px !important;
    justify-content: center;
    align-items: center;
  }
}

.navbar--dark .navbar__center__search {
  background-color: rgba(var(--primary-color-rgb), 0.7);
  border-color: rgba(var(--primary-color-rgb), 0.7);
}

.navbar--dark .navbar__center__search {
  color: var(--white-color);
}

.navbar--dark .navbar__center__search .anticon.anticon-search {
  color: var(--white-color);
}

.navbar--dark .navbar__center__search input {
  background-color: transparent;
  color: var(--black-color);
}
.price {
  text-align: center !important;
  font-size: 1rem;
  color: black;

  margin-bottom: auto;
}
.cancelNav img {
  width: 110px;
  height: 65px;
  margin-left: -30px;
  margin-top: -8px;
  margin-bottom: -8px;
}
.rounded-div {
  display: flex;

  border-radius: 50%;

  background-color: white;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.rounded-div_cancel {
  display: flex;

  border-radius: 50%;

  background-color: white;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.menu {
  font-size: 1.2rem;
  color: black;
}
.circle-image {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

.circle-image img {
  display: block;
  width: 100%;
  height: auto;
}
.ChiamaCocaiSupport {
  display: flex;
  background-color: #fab626;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;

  border-radius: 10px;
  color: black;
  align-items: center;
  justify-content: center;
}
@media (max-width: 500px) {
  /* Adjust padding for larger screens */
  .ChiamaCocaiSupport {
    /* padding-inline: 35px; */
    /* padding-right: 20px; */
    margin-top: 5px;
    width: 80px;
    padding-left: 5px;
    margin-left: -10px;
  }
}
.navbar-show-pin {
  margin-left: -70px;
}
.navbar-pin {
  display: flex !important;

  gap: 10px;
}
