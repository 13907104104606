.bigbutton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  width: 60px;
  height: 60px;
  max-width: 96px;
  max-height: 96px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 7px 14px 2px rgba(77, 77, 77, 0.1);
  transition: width 0.3s ease-in, height 0.3s ease-in;
}
.with_opacity:hover {
  width: 66px;
  height: 66px;
}
.with_opacity {
  background-color: rgba(27, 27, 49, 0.6);
  /* border: 5px solid rgba(27, 27, 49, 0.6); */
}
@media only screen and (max-width: 375px) {
}
.bigbutton__img {
  /* margin-top: -20px; */
  margin-bottom: 10px;
  height: 80% !important;
  width: 40px !important;
}

.bigbutton__img--translated {
  height: 50px !important;
}
.bigbutton__img__container {
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: 10px; */
}
.ondemand_big_btn {
  height: 104% !important;
  margin-top: 10px;
}
.food_footer_btn {
  /* margin-bottom: 5px; */
  height: 70px !important;
}
/* @media only screen and (max-width: 375px) {
  .bigbutton__img {
    transform: translateY(2px) scale(0.9) !important;
  }
  .bigbutton__img.bigbutton__img--translated {
    transform: translate(10px, 2px) scale(0.9) !important;
  }
}
@media only screen and (max-width: 360px) {
  .bigbutton__img {
    transform: translateY(3px) scale(0.8) !important;
  }
  .bigbutton__img.bigbutton__img--translated {
    transform: translate(10px, 3px) scale(0.8) !important;
  }
}
@media only screen and (max-width: 350px) {
  .bigbutton__img {
    transform: translateY(3px) scale(0.7) !important;
  }
  .bigbutton__img.bigbutton__img--translated {
    transform: translate(10px, 3px) scale(0.7) !important;
  }
  .bigbutton__img__container {
    margin-bottom: 5px !important;
  }
} */
.bigbutton__title {
  margin-bottom: 7px;
  display: flex;
  align-self: center;
  font-size: 74%;
  margin-top: -10px;
  margin-bottom: 13%;
  font-weight: 600;
  text-align: center;
}
@media only screen and (max-width: 375px) {
  .bigbutton__title {
    font-size: 60%;
  }
}
@media only screen and (max-width: 318px) {
  .bigbutton__title {
    font-size: 50%;
  }
}
@media only screen and (max-width: 270px) {
  .bigbutton__title {
    font-size: 40%;
  }
}
.no_opacity {
  opacity: 0;
}
.closedStore {
  opacity: 0.3;
}
.store_closed_text {
  position: absolute;
  top: 63px;
  left: 20px;
  transform: translate(-50%, -50%) rotate(-45deg);

  font-family: 'Gotham Rounded', sans-serif;
  /* rotate: -45deg; */
  color: orange;
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
  z-index: 9999999;
  background-color: rgba(27, 27, 49, 0.1);

  padding-left: 2px;
  padding-right: 2px;
}
.image-overlay-container {
  position: relative;
  display: inline-block;
}
.image-overlay-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -10px;
  background: rgba(27, 27, 49, 0.4);
  pointer-events: none;
}
