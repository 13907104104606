.bigswitcher--disabled {
  pointer-events: none;
  cursor: default;
  color: var(--light-grey) !important;
}
.bigswitcher__content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 3px;
  color: var(--primary-color);
}

.bigswitcher__content__left,
.bigswitcher__content__right {
  width: 50%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  cursor: pointer;
}

.bigswitcher__content--selected {
  background-color: var(--primary-color);
  border-radius: 18.5455px;
  color: var(--white-color);
}

.bigswitcher__content__additional {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*Temporary style to hide element*/
  display: none;
}
.bigswitcher__content--additional {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigswitcher__content__dot {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  margin: 0 9px;

}

.bigswitcher__content__dot--inverted {
  background-color: var(--primary-color);
}
