.LightCathering {
  /* overflow-y: hidden; */
  height: 100vh;
  background-color: #1b1b31;
}
.LightCathering__header {
  width: 100%;
  height: 40px;
  background-size: cover;
  position: relative;
}

.LightCathering__cancel {
  position: absolute;
  left: 24px;
  top: 56px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0 10px 20px 4px rgba(77, 77, 77, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.LightCathering__cancelicon {
  width: 14px;
  height: 14px;
  background-size: cover;
  cursor: pointer;
}
.LightCatheringBody {
  /* opacity: 0.7; */
}
.loader_lightCathering {
  display: flex;
  justify-content: center;
  margin-top: 15%;
}
.LightCatheringBody_content {
  background-color: white;
  height: 100%;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  padding: 50px 25px;
  -webkit-box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
  box-shadow: 0 25px 23px rgba(0, 0, 0, 0.15);
  border: 1.5px solid rgba(255, 255, 255, 0.06);
  transition: transform 0.5s;
}
.expanded {
  transform: scale(0.5);
}
.LightCathering__tabss {
  margin: 17px 25px 10px 32px;
  color: white;
  background-color: transparent;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
  margin-right: -18px;
}
.LightCatheringBody {
  margin: 10px;
  padding: 20px;
}
.LightCatheringBody_content {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.custom-form label,
.custom-form input,
.custom-form textarea,
.custom-form button {
  color: white;
}
.custom-form .ant-input,
.custom-form .ant-input::placeholder {
  /* color: white; */
}
@media (max-width: 330px) {
  .LightCathering__tabss {
    font-size: 25px;
    margin-top: 20px;
  }
}
@media (max-width: 475px) {
  .LightCathering__tabss {
    margin-left: -15px;
  }
  .LightCathering__cancel {
    position: absolute;
    left: 24px;
    top: 60px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0 10px 20px 4px rgba(77, 77, 77, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 291px) {
  .LightCathering__tabss {
    font-size: 23px;
    margin-top: 18px;
  }
}
@media (max-width: 281px) {
  .LightCathering__tabss {
    font-size: 20px;
    margin-top: 28px;
  }
}
.light_cathering_success_text {
  color: whitesmoke;
  font-size: 18px;
}

.loader_light_cathering {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_cube {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.loader_cube--glowing {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.loader_cube--color {
  z-index: 1;
  filter: blur(2px);
  background: linear-gradient(135deg, #1afbf0, #da00ff);
  animation: loadtwo 2.5s ease-in-out infinite;
}

@keyframes loadtwo {
  50% {
    transform: rotate(-80deg);
  }
}
.submit_lightCatering {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.submit_lightCatering_btn {
  width: 60%;
  height: 40px;
  padding: auto;
}
/* Targeting the input of the DatePicker and TimePicker */
.ant-picker input {
  color: white !important; /* Ensure the selected text is white */
}

/* Target the placeholder text color */
.ant-picker input::placeholder {
  color: rgba(255, 255, 255, 0.6) !important; /* Optional: white placeholder */
}
