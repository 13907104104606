.login.ant-layout {
  background-color: var(--primary-color);
  position: relative;
}

.login__container {
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  max-width: 408px;
}

.login__container__imagecontainer {
  position: relative;
  height: 412px;
}

.login__container__cocaisign {
  position: absolute;
  top: 80px;
  left: 5.072%;
  transform: rotate(-4.21deg);
  width: 88.64%;
}

.login__container__cocaibarch {
  position: absolute;
  right: 5.55%;
  top: 150px;
  z-index: 10;
}

.login__container__waves {
  position: absolute;
  top: 200px;
  left: 0;
  width: 408px;

  z-index: 0;
}

.login__formitem {
  margin-bottom: 20px;
}
.login__formitem--password {
  margin-bottom: 30px;
}

.login__container__loginCard {
  background: #f8f6f0;
  margin-top: -140px !important;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  height: calc(100vh - 268px);
}

.login .login__container__loginCard--title {
  text-align: center;
  font-family: 'Gotham Rounded', sans-serif;
  /*important needed because of ant design override*/
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 20px !important;
  color: #1b1b31 !important;
}

.login .login__container__loginCard,
.login .login__container__loginCard > .ant-card-head {
  border-radius: 25px 25px 0 0;
}

.login .login__container__loginCard .ant-card-head-title {
  padding-bottom: 0;
  padding-top: 22px;
}

.login__container__loginCard .ant-card-body {
  padding: 14px 24px 30px 24px;
}

.login .login__container__loginCard .divider {
  margin: 5px 0;
}

.login__input {
  height: 45px;
  background-color: rgba(231, 236, 239, 0.2) !important;
  border-radius: 10px;
  border-bottom: 2px solid #fab627;
  padding: 6.5px 25px;
}

.login__input .ant-input {
  background-color: transparent;
}

.login__input .ant-input::placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b1b31;
  opacity: 0.5;
}

.login__input .ant-input-prefix {
  margin-right: 20px;
}

.login__submitbutton {
  height: 54px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 14px;
}

.login__submitbutton > span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: var(--primary-color);
  text-shadow: unset;
}

.login__continuebutton {
  width: 100%;
  height: 60px;
  background: #f4f4f0;
  border: 1px solid #fab627;
  border-radius: 20px;
}
.login__continuebutton > span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: var(--primary-color);
}

.login__divider {
  margin-left: 19px;
  margin-right: 19px;
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}
.login__divider__text {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  z-index: 2;
  /* background: #f8f6f0; */
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 300px;
  color: #1b1b31;
  background-color: white;
  opacity: 0.75;
}
.login__prefiximg {
  height: 27px;
}

.login__signupcall > div {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #1b1b31;

  opacity: 0.75;
}

.login__privacy {
  margin-top: 40px;
  text-align: center;
  color: grey;
  margin-bottom: -10px !important;
}
.login__privacy a {
  color: grey !important;
  text-decoration: underline;
}
.Link_login_page {
  font-weight: 600;
  color: #1b1b31;
}
.login__input {
  height: 45px;
  background-color: rgba(231, 236, 239, 0.2) !important;
  border-radius: 10px;
  border-bottom: 2px solid #fab627;
  padding: 6.5px 25px;
}

.login__input .ant-input {
  background-color: transparent;
}

.login__input .ant-input::placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b1b31;
  opacity: 0.5;
}

.login__input .ant-input-prefix {
  margin-right: 20px;
}

.login__submitbutton {
  height: 54px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 29px; /* 14 px if register button is also here */
}

.login__submitbutton > span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: var(--primary-color);
  text-shadow: unset;
}

.login__continuebutton {
  width: 100%;
  height: 60px;
  background: #f4f4f0;
  border: 1px solid #fab627;
  border-radius: 20px;
}
.login__continuebutton > span {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: var(--primary-color);
}

.login__divider {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.login__prefiximg {
  height: 27px;
}

.login__signupcall > div {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #1b1b31;

  opacity: 0.75;
}

.login .forgot-password {
  display: flex;
  /*justify-content: flex-end;*/
  justify-content: center;
  margin-top: 10px;
}

.login .forgot-password > a {
  font-size: '14px';
}

.login__container__loginCard::-webkit-scrollbar {
  width: 5px;
}

.login__container__loginCard::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

.login__container__loginCard::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--secondary-color-rgb, 0.7));
}
.Social_logins {
  cursor: pointer;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid #d8d9da;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.Social_logins_text {
  display: inline;
  text-align: center;

  margin: auto;
  margin-left: 30px;
  /* justify-content: center;
  align-items: center; */
}
.Social_logins_email {
  cursor: pointer;

  border: 1px solid #d8d9da;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  height: 50px;
  border-radius: 20px;
  /* padding-left: 15px; */
}
.Social_logins_email_image {
  margin-left: 15px;
}
.Social_logins_email_text {
  text-align: center;
  margin: auto;
  margin-left: 40px;
}
.verify-phone-heading {
  font-family: 'Gotham Rounded', sans-serif;
  margin-top: -20px;
  text-align: center;
}
