.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 32px;
  padding-top: 10px;
  border-top: 2px solid var(--light-grey);
  cursor: pointer;
  color: var(--primary-color);
}
.no-border {
  border-top: none;
  margin: 10px 10px;
  padding-top: 0px;
}

.itemContainer span {
  display: flex;
  align-items: center;
}

.itemContainer div {
  margin: 0;
  margin-left: 20px;
}

.itemContainer--danger {
  color: var(--error-color) !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
  color: orange;
}
