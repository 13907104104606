@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

// font
@font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #1B1B31;
@primary-color-hover: color(~`colorPalette('@{primary-color}', 5) `);
@primary-color-active: color(~`colorPalette('@{primary-color}', 7) `);

@secondary-color: #FAB627;
@error-color: #fa541c;
@text-color: #626262;

// Background color for `<body>`
@body-background: @primary-color;
// Base background color for most components
@component-background: #FFF;

// sider
@layout-sider-background: #FFF;
@menu-bg: transparent;
@menu-item-color: #000;
@menu-highlight-color: #000;
@menu-inline-submenu-bg: #CDCDCD;
@menu-item-active-bg: @secondary-color;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label>label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}

.ant-menu-submenu-popup>.ant-menu {
  background-color: #2a132e;
}

.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #000;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}

.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}

.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

// Buttons
@btn-border-radius-base: 15px;
@btn-border-radius-sm: 15px;

@btn-primary-color: @primary-color;
@btn-primary-bg: @secondary-color;
@btn-primary-border: @secondary-color;

// Input
@input-bg: rgba(231, 236, 239, 0.2);
@input-number-handler-bg: rgba(231, 236, 239, 0.2);

// .btn-secondary-bg,
// .btn-secondary-bg:focus {
//   border-color: @primary-color;
//   background: @primary-color;
//   color: #FFF;
// }

// .btn-secondary-bg:hover {
//   border-color: rgba(@primary-color, 0.75);
//   background: rgba(@primary-color, 0.75);
//   color: #FFF;
// }

// LINK
@link-color: @secondary-color;

// Tooltip
// Tooltip text color
@tooltip-color: #FFF;
// Tooltip background color
@tooltip-bg: @primary-color;

.input,
.ant-input-number,
.ant-picker,
div.ant-select:not(.ant-select-customize-input) div.ant-select-selector {
  border: 0;
  border-bottom: 1px solid @secondary-color;
  background: rgba(231, 236, 239, 0.2);
}

.ant-checkbox-inner {
  border-color: @secondary-color;
  background: rgba(231, 236, 239, 0.2);
}

.modal--blue .ant-modal-content {
  background-color: @primary-color;
  color: #FFF;

  .anticon {
    color: #FFF;
  }
}