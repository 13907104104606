.profile {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../public/images/waves_bkg.svg');
}

.profile__navbar {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
}
@media only screen and (max-width: 768px) {
/* For tablets and smaller screens, add some spacing on top */
  .profile__navbar {
    top: 33px;
  }
}
.profile__mainHeader {
  width: 100vw;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__mainHeader img {
  transform: scale(2);
}

.profile__mainContainer {
  width: 100vw;
  height: 75vh;
  background-color: var(--white-color);
  border-radius: 30px 30px 0 0;
}

.profile__mainContainer__header {
  text-align: center;
}

.profile__mainContainer__header h1 {
  padding-top: 12px;
  margin-bottom: 0;
}
.profile__mainContainer__header p {
  color: var(--medium-grey);
}
