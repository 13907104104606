body {
  background: #39d;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-menu {
  width: 250px;
  height: 250px;
  /* margin: 0 auto; */
  position: relative;
  z-index: 10000;
}

.circle {
  width: 250px;
  height: 250px;
  opacity: 0;
  margin-top: 15px;
  margin-left: 15px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);

  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.open.circle {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.circle a {
  text-decoration: none;
  color: white;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  text-align: center;
}

.circle a:hover {
  color: #eef;
}

.menu-button {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  text-align: center;
  color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 40px;
  padding: 10px;
  background: transparent;
}

.menu-button:hover {
  background-color: transparent;
}

@media (min-width: 800px) {
  .circular-menu {
    width: 250px;
    height: 250px;
    margin-left: 70%;
    position: relative;
    z-index: 10000;
  }
}

/* Author stuff */
h1.author {
  text-align: center;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
}

h1.author a {
  color: #348;
  text-decoration: none;
}

h1.author a:hover {
  color: #ddd;
}
